import React, { useState } from 'react';
//import logo from './logo.svg';
import '../App.css';
import {  MDBInput,  MDBBtn,  MDBCheckbox,  MDBRow,  MDBCol,  MDBIcon,  MDBInputGroup,  MDBNavbar,  MDBContainer
} from 'mdb-react-ui-kit';
import { useNavigate} from 'react-router-dom'
import Axios from 'axios'

function Welcome() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  sessionStorage.removeItem('loggedIn');
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('userType');
  localStorage.removeItem('userID');
  localStorage.removeItem('name');
  localStorage.removeItem('position');


  const [user, setUser] = useState('')
  const [pwd, setPwd] = useState('')

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const handleSubmit = async e => {
    e.preventDefault();
    //navigate('/ApplicationForm')
    //alert('Uname = '+user+ ' pWD = '+pwd)
   setHandleSubmitBtn('Loading')
    
    var formData = new FormData(this)
    formData.append('apiType', 'login')
    formData.append('username', user)
    formData.append('password', pwd)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/reactphp/login.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      //alert(response.data)
      if(response.data.loggedIn === 'Yes'){
      setUser('')
      setPwd('')
      //alert(response.data)
      sessionStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('loggedIn', JSON.stringify(response.data.loggedIn))
      localStorage.setItem('userType', JSON.stringify(response.data.userType))
      localStorage.setItem('userID', JSON.stringify(response.data.userID))
      localStorage.setItem('name', JSON.stringify(response.data.name))
      localStorage.setItem('position', JSON.stringify(response.data.position))

      navigate('/Home')
    }
      else{      
      alert('Error : Failed To Login')
      //alert(response.data.name)
    }
    }).catch((err) => alert("Connection Error : Check Internet Connectivity")
    );
 
};

return (
    <>
   <div className="row">
  <div className="col-12 ">
  
      <nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar col-12">
        <div className="container">
          <a className="navbar-brand" href="#"><strong>Tabo's Kitchen Party</strong></a>
          <button className="navbar-toggler blue" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="Register" >Register</a>
              </li>
              <li className="nav-item">
            <a className="nav-link "  href="RSVP" >RSVP</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="Download" >Download Your Wedding Card Here</a>
          </li>
            </ul>
          </div>
        </div>
      </nav>


    {/* Main Navigation */}

    {/* 
    <footer className="page-footer pt-0 mt-5">
      <div className="footer-copyright py-3 text-center" style={{ backgroundColor: 'black', color: 'white' }}>
        <p>
          Chilenje ECZ
        </p>
        <div className="container-fluid font-small">
          Powered : <a href="https://www.kanchtech.com" target="_blank" style={{ color: 'yellow' }}> Kanch Innovative Technologies</a>
        </div>
      </div>
    </footer>
    */}
  </div>


</div>


{/* Main content */}
<main className='white'> 

  {/* First container */}
  <div className="container">

    {/* Section: About */}
    <section id="about">

      {/* Section heading */}
      <h2 className="mdb-color-text darken-3 text-uppercase font-weight-bold mb-3 pt-4 mt-5 wow fadeIn" data-wow-delay=".2s">Tabo's Kitchen Party</h2>

      <hr className="mb-5" />

      {/* Grid row */}
      <div className="row mt-5 mb-2">

        {/* Grid column */}
        <div className="col-lg-12 col-md-12 mb-2 mr-auto wow fadeIn" data-wow-delay=".4s">

          {/* Image */}
          <img src={require('./img/programme.jpg')} className="img-fluid z-depth-1-half" alt="Thabo and Nono" />

        </div>
        {/* Grid column */}

      </div>
      {/* Grid row */}

    </section>
    {/* Section: About */}

    <hr className="wow fadeIn mt-5" data-wow-delay="0.4s" />

  
  </div>

</main>


    </>
  );
}

export default Welcome;
