import React, { useState, useEffect } from 'react';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody } from 'mdb-react-ui-kit'; // Remove unused imports
import Axios from 'axios';
import $ from 'jquery'; // Import jQuery for DataTables
import 'datatables.net'; // Import DataTables CSS and JS files
import './DTtablesStyling.css'

function Admin() {
  const server_app_api_php = window.SERVER_APP_API_PHP;

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('');
  const [rows, setDBtableView] = useState([]);

  useEffect(() => {
    setHandleSubmitBtn('Loading');
    const formData = new FormData();
    formData.append('apiType', 'getViewAttendees');
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(function (response) {
        setHandleSubmitBtn('');
        setDBtableView(response.data);
        setTimeout(function () {
          $('#dataTable').DataTable()
          //addTableButtons()
        }, 3000)
      })
      .catch(function (response) {
        alert('Error: Check Your Internet Connection');
      });
  }, []);

  /*
  useEffect(() => {
    $('#dataTable').DataTable(); // Initialize DataTables after data is fetched and rows are updated
  }, [rows]);
*/
//let fileNames = "";
  const downloadDoc = async (fileName) => {
    //let fileName = document.getElementById("download").value;
    fileName = fileName.toUpperCase();
    //fileNames = fileName;
    fileName = fileName+".pdf"
   
    //alert(fileName)
        //const response = await Axios.post({server_app_api_php}+'/downloadCard.php', formData, {
          try {
            const response = await Axios.get(server_app_api_php+'/downloadCard.php?file='+fileName, {
                responseType: 'blob'
            });
            if (response.status === 200) {
              //alert(response.data);
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const downloadLink = document.createElement('a');
                downloadLink.href = window.URL.createObjectURL(blob);
                downloadLink.download = fileName;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        } catch (error) {
            alert('Error downloading file:', error);
        }
    
  }
  return (
    <MDBContainer fluid className='p-4 background-radial-gradient overflow-hidden'>

<div className="row mb-5">
  <div className="col-12 ">
  
      <nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar col-12">
        <div className="container">
          <a className="navbar-brand" href="."><strong>Tabo's Kitchen Party</strong></a>
          <button className="navbar-toggler blue" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="." >Home</a>
              </li>
              <li className="nav-item">
            <a className="nav-link "  href="Register" >Register</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="RSVP" >RSVP</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="Download" >Download Your Wedding Card Here</a>
          </li>
            </ul>
          </div>
        </div>
      </nav>

  </div>


</div>

      <MDBRow>
        <MDBCol md='12' className='position-relative text-center text-md-start d-flex flex-column justify-content-center'>
          <MDBCard className='my-5 bg-glass'>
            <MDBCardBody className='p-5'>
              <table id='dataTable' className='table table-striped'>
                <thead>
                  <tr>
                    <th>SN</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Gender</th>
                    <th>Relationship</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.gender}</td>
                      <td>{item.relationship}</td>
                      <td>
                        <span
                          onClick={() => {
                            downloadDoc(item.ref);
                          }}
                          className='fa fa-download green-text'
                          title='Download Card'
                          style={{ cursor: 'pointer' }}
                        ></span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default Admin;
