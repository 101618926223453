import { useState, useEffect } from 'react'
import Axios from 'axios'

const RSVP = () => {
  const server_app_api_php = window.SERVER_APP_API_PHP

  let [handleSubmitBtn, setHandleSubmitBtn] = useState('')
  const [phone, setPhone] = useState('');
  let [attending, setAttending] = useState(false);

  const [validPhone, setValidPhone] = useState(false)
  const [phoneFocus, setPhoneFocus] = useState(false)

  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;


  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  }

  const handleAttendingChange = (e) => {
    setAttending(e.target.checked);
  }

  useEffect(() => {
    const result = PHONE_REGEX.test(phone)
    setValidPhone(result)
    }, [phone])


  const handleSubmit = (e) => {
    e.preventDefault();
    if(attending === true){
      attending = "Yes";
    }
    else{
      attending = "No";
    }
    var formData = new FormData(this)
    formData.append('apiType', 'rsvp')
    formData.append('phone', phone)
    formData.append('attending', attending)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      alert(response.data)   
      setPhone('');
      setAttending(false);
    }).catch((err) => alert("Connection Error : Check Internet Connectivity"),
    setHandleSubmitBtn('')
    );
  }

  return (
    <div className="container">

<div className="row mb-5">
  <div className="col-12 ">
  
      <nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar col-12">
        <div className="container">
          <a className="navbar-brand" href="."><strong>Tabo's Kitchen Party</strong></a>
          <button className="navbar-toggler blue" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="." >Home</a>
              </li>
              <li className="nav-item">
            <a className="nav-link "  href="Register" >Register</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="Download" >Download Your Wedding Card Here</a>
          </li>
            </ul>
          </div>
        </div>
      </nav>

  </div>


</div>

<hr />

      <h2 className="mt-5 text-left">RSVP</h2>
      <form onSubmit={handleSubmit}>
        <div className="md-form md-outline">
          <label htmlFor="phone">Phone Number</label>
          <input type="text" className="form-control" id="phone" value={phone} onChange={handlePhoneChange} 
                    onFocus={() => setPhoneFocus(true)}
                    onBlur={() => setPhoneFocus(false)}
          />

          <p
                  className='red-text'
                  hidden={
                      phoneFocus === false || validPhone === true
                  }
                >
                  It should be a Correct Phone Number and there should be 10 numerical values.
                </p>
        </div>
        <div className="form-group form-check">
          <input type="checkbox" className="form-check-input" id="attending" checked={attending} onChange={handleAttendingChange} />
          <label className="form-check-label" htmlFor="attending">I am attending</label>
        </div>
        <button type="submit" className="btn btn-primary"
        disabled={phone === '' || !validPhone}
        >
           <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
            </div>
          Submit</button>
      </form>
    </div>
  );
}

export default RSVP;
