import React, { useEffect } from 'react';
import WOW from 'wowjs';
//import $ from 'jquery';
const CartPage = () => {
  useEffect(() => {
    // WOW.js init
   /* new WOW().init();

    // MDB Lightbox Init
    $('#mdb-lightbox-ui').load('mdb-addons/mdb-lightbox-ui.html');

    // Tooltips Initialization
    $('[data-toggle="tooltip"]').tooltip();

    // SideNav Initialization
    $('.button-collapse').sideNav();

    // Material Select Initialization
    $('.mdb-select').material_select();*/
  }, []);

  return (
    <div className="cart-v1 hidden-sn white-skin animated">
      {/* Navigation */}
      <header>
        {/* Sidebar navigation */}
        <ul id="slide-out" className="side-nav custom-scrollbar sn-bg-4">
          {/* Logo */}
          <li>
            <div className="logo-wrapper waves-light">
              <a href="#">
                <img src="https://mdbootstrap.com/img/logo/mdb-transparent.png" className="img-fluid flex-center" />
              </a>
            </div>
          </li>
          {/* Logo */}
          {/* Social */}
          <li>
            <ul className="social">
              <li>
                <a className="fb-ic">
                  <i className="fab fa-facebook-f"> </i>
                </a>
              </li>
              <li>
                <a className="pin-ic">
                  <i className="fab fa-pinterest"> </i>
                </a>
              </li>
              <li>
                <a className="gplus-ic">
                  <i className="fab fa-google-plus-g"> </i>
                </a>
              </li>
              <li>
                <a className="tw-ic">
                  <i className="fab fa-twitter"> </i>
                </a>
              </li>
            </ul>
          </li>
          {/* Social */}
          {/* Search Form */}
          <li>
            <form className="search-form" role="search">
              <div className="form-group md-form mt-0 pt-1 waves-light">
                <input type="text" className="form-control" placeholder="Search" />
              </div>
            </form>
          </li>
          {/* Search Form */}
          {/* Side navigation links */}
          <li>
            <ul className="collapsible collapsible-accordion">
              <li>
                <a className="collapsible-header waves-effect arrow-r">
                  <i className="fas fa-shopping-cart"></i> Cart page
                  <i className="fas fa-angle-down rotate-icon"></i>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <a href="../cart/cart-v1.html" className="waves-effect">Cart V.1</a>
                    </li>
                    <li>
                      <a href="../cart/cart-v2.html" className="waves-effect">Cart V.2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header waves-effect arrow-r">
                  <i className="far fa-hand-pointer"></i> Category page
                  <i className="fas fa-angle-down rotate-icon"></i>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <a href="../category/category-v1.html" className="waves-effect">Category V.1</a>
                    </li>
                    <li>
                      <a href="../category/category-v2.html" className="waves-effect">Category V.2</a>
                    </li>
                    <li>
                      <a href="../category/category-v3.html" className="waves-effect">Category V.3</a>
                    </li>
                    <li>
                      <a href="../category/category-v4.html" className="waves-effect">Category V.4</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header waves-effect arrow-r">
                  <i className="far fa-bookmark"></i> Homepage
                  <i className="fas fa-angle-down rotate-icon"></i>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <a href="../homepage/homepage-v1.html" className="waves-effect">Homepage V.1</a>
                    </li>
                    <li>
                      <a href="../homepage/homepage-v2.html" className="waves-effect">Homepage V.2</a>
                    </li>
                    <li>
                      <a href="../homepage/homepage-v3.html" className="waves-effect">Homepage V.3</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a className="collapsible-header waves-effect arrow-r">
                  <i className="fas fa-camera-retro"></i> Product page
                  <i className="fas fa-angle-down rotate-icon"></i>
                </a>
                <div className="collapsible-body">
                  <ul>
                    <li>
                      <a href="../product/product-v1.html" className="waves-effect">Product V.1</a>
                    </li>
                    <li>
                      <a href="../product/product-v2.html" className="waves-effect">Product V.2</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <a href="../contact/contact.html" className="collapsible-header waves-effect">
                  <i className="fas fa-envelope"></i> Contact</a>
              </li>
            </ul>
          </li>
          {/* Side navigation links */}
          <div className="sidenav-bg mask-strong"></div>
        </ul>
        {/* Sidebar navigation */}
        {/* Navbar */}
        <nav className="navbar fixed-top navbar-expand-lg navbar-light scrolling-navbar white">
          <div className="container">
            {/* SideNav slide-out button */}
            <div className="float-left mr-2">
              <a href="#" data-activates="slide-out" className="button-collapse">
                <i className="fas fa-bars"></i>
              </a>
            </div>
            <a className="navbar-brand font-weight-bold" href="#">
              <strong>SHOP</strong>
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-4" aria-controls="navbarSupportedContent-4" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent-4">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ">
                  <a className="nav-link dark-grey-text font-weight-bold" href="#" data-toggle="modal" data-target="#cart-modal-ex">
                    <span className="badge danger-color">4</span>
                    <i className="fas fa-shopping-cart blue-text" aria-hidden="true"></i>
                    <span className="clearfix d-none d-sm-inline-block">Cart</span>
                  </a>
                </li>
                <li className="nav-item ml-3">
                  <a className="nav-link waves-effect waves-light dark-grey-text font-weight-bold" href="#">
                    <i className="fas fa-cog blue-text"></i> Settings</a>
                </li>
                <li className="nav-item dropdown ml-3">
                  <a className="nav-link dropdown-toggle waves-effect waves-light dark-grey-text font-weight-bold" id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="fas fa-user blue-text"></i> Profile </a>
                  <div className="dropdown-menu dropdown-menu-right dropdown-cyan" aria-labelledby="navbarDropdownMenuLink-4">
                    <a className="dropdown-item waves-effect waves-light" href="#">My account</a>
                    <a className="dropdown-item waves-effect waves-light" href="#">Log out</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {/* Navbar */}
      </header>
      {/* Navigation */}
      {/* Main Layout */}
      <main>
        {/* Main Container */}
        <div className="container">
          <section className="section my-5 pb-5">
            {/* Shopping Cart table */}
            <div className="table-responsive">
              <table className="table product-table table-cart-v-1">
                {/* Table head */}
                <thead>
                  <tr>
                    <th></th>
                    <th className="font-weight-bold">
                      <strong>Product</strong>
                    </th>
                    <th className="font-weight-bold">
                      <strong>Color</strong>
                    </th>
                    <th></th>
                    <th className="font-weight-bold">
                      <strong>Price</strong>
                    </th>
                    <th className="font-weight-bold">
                      <strong>QTY</strong>
                    </th>
                    <th className="font-weight-bold">
                      <strong>Amount</strong>
                    </th>
                    <th></th>
                  </tr>
                </thead>
                {/* Table head */}
                {/* Table body */}
                <tbody>
                  {/* First row */}
                  <tr>
                    <th scope="row">
                      <img src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/13.jpg" alt="" className="img-fluid z-depth-0" />
                    </th>
                    <td>
                      <h5 className="mt-3">
                        <strong>iPhone</strong>
                      </h5>
                      <p className="text-muted">Apple</p>
                    </td>
                    <td>White</td>
                    <td></td>
                    <td>$800</td>
                    <td className="text-center text-md-left">
                      <span className="qty">1 </span>
                      <div className="btn-group radio-group ml-2" data-toggle="buttons">
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option1" />&mdash;
                        </label>
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option2" />+
                        </label>
                      </div>
                    </td>
                    <td className="font-weight-bold">
                      <strong>$800</strong>
                    </td>
                    <td>
                      <button type="button" className="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Remove item">X</button>
                    </td>
                  </tr>
                  {/* First row */}
                  {/* Second row */}
                  <tr>
                    <th scope="row">
                      <img src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/6.jpg" alt="" className="img-fluid z-depth-0" />
                    </th>
                    <td>
                      <h5 className="mt-3">
                        <strong>Headphones</strong>
                      </h5>
                      <p className="text-muted">Sony</p>
                    </td>
                    <td>Red</td>
                    <td></td>
                    <td>$200</td>
                    <td className="text-center text-md-left">
                      <span className="qty">3 </span>
                      <div className="btn-group radio-group ml-2" data-toggle="buttons">
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option1" />&mdash;
                        </label>
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option2" />+
                        </label>
                      </div>
                    </td>
                    <td className="font-weight-bold">
                      <strong>$600</strong>
                    </td>
                    <td>
                      <button type="button" className="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Remove item">X</button>
                    </td>
                  </tr>
                  {/* Second row */}
                  {/* Third row */}
                  <tr>
                    <th scope="row">
                      <img src="https://mdbootstrap.com/img/Photos/Horizontal/E-commerce/Products/1.jpg" alt="" className="img-fluid z-depth-0" />
                    </th>
                    <td>
                      <h5 className="mt-3">
                        <strong>iPad Pro</strong>
                      </h5>
                      <p className="text-muted">by FifeSteps</p>
                    </td>
                    <td>Gold</td>
                    <td></td>
                    <td>$600</td>
                    <td className="text-center text-md-left">
                      <span className="qty">2 </span>
                      <div className="btn-group radio-group ml-2" data-toggle="buttons">
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option1" />&mdash;
                        </label>
                        <label className="btn btn-sm btn-primary btn-rounded">
                          <input type="radio" name="options" id="option2" />+
                        </label>
                      </div>
                    </td>
                    <td className="font-weight-bold">
                      <strong>$1200</strong>
                    </td>
                    <td>
                      <button type="button" className="btn btn-sm btn-primary" data-toggle="tooltip" data-placement="top" title="Remove item">X</button>
                    </td>
                  </tr>
                  {/* Third row */}
                  {/* Fourth row */}
                  <tr>
                    <td colSpan="3"></td>
                    <td>
                      <h4 className="mt-2">
                        <strong>Total</strong>
                      </h4>
                    </td>
                    <td className="text-right">
                      <h4 className="mt-2">
                        <strong>$2600</strong>
                      </h4>
                    </td>
                    <td colSpan="3" className="text-right">
                      <button type="button" className="btn btn-primary btn-rounded">Complete purchase <i className="fas fa-angle-right right"></i></button>
                    </td>
                  </tr>
                  {/* Fourth row */}
                </tbody>
                {/* Table body */}
              </table>
            </div>
            {/* Shopping Cart table */}
          </section>
        </div>
        {/* Main Container */}
      </main>
      {/* Main Layout */}
      {/* Footer */}
      <footer className="page-footer text-center text-md-left stylish-color-dark pt-0">
        <div style={{ backgroundColor: '#4285f4' }}>
          <div className="container">
            {/* Grid row */}
            <div className="row py-4 d-flex align-items-center">
              {/* Grid column */}
              <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
                <h6 className="mb-0 white-text">Get connected with us on social networks!</h6>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-md-6 col-lg-7 text-center text-md-right">
                {/* Facebook */}
                <a className="fb-ic ml-0 px-2">
                  <i className="fab fa-facebook-f white-text"> </i>
                </a>
                {/* Twitter */}
                <a className="tw-ic px-2">
                  <i className="fab fa-twitter white-text"> </i>
                </a>
                {/* Google + */}
                <a className="gplus-ic px-2">
                  <i className="fab fa-google-plus-g white-text"> </i>
                </a>
                {/* Linkedin */}
                <a className="li-ic px-2">
                  <i className="fab fa-linkedin-in white-text"> </i>
                </a>
                {/* Instagram */}
                <a className="ins-ic px-2">
                  <i className="fab fa-instagram white-text"> </i>
                </a>
              </div>
              {/* Grid column */}
            </div>
            {/* Grid row */}
          </div>
        </div>
        {/* Footer Links */}
        <div className="container mt-5 mb-4 text-center text-md-left">
          <div className="row mt-3">
            {/* First column */}
            <div className="col-md-3 col-lg-4 col-xl-3 mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Company name</strong>
              </h6>
              <hr className="blue mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
              <p>Here you can use rows and columns here to organize your footer content. Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
            </div>
            {/* First column */}
            {/* Second column */}
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Products</strong>
              </h6>
              <hr className="blue mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
              <p>
                <a href="#!">MDBootstrap</a>
              </p>
              <p>
                <a href="#!">MDWordPress</a>
              </p>
              <p>
                <a href="#!">BrandFlow</a>
              </p>
              <p>
                <a href="#!">Bootstrap Angular</a>
              </p>
            </div>
            {/* Second column */}
            {/* Third column */}
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Useful links</strong>
              </h6>
              <hr className="blue mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
              <p>
                <a href="#!">Your Account</a>
              </p>
              <p>
                <a href="#!">Become an Affiliate</a>
              </p>
              <p>
                <a href="#!">Shipping Rates</a>
              </p>
              <p>
                <a href="#!">Help</a>
              </p>
            </div>
            {/* Third column */}
            {/* Fourth column */}
            <div className="col-md-4 col-lg-3 col-xl-3">
              <h6 className="text-uppercase font-weight-bold">
                <strong>Contact</strong>
              </h6>
              <hr className="blue mb-4 mt-0 d-inline-block mx-auto" style={{ width: 60 }} />
              <p>
                <i className="fas fa-home mr-3" /> New York, NY 10012, US</p>
              <p>
                <i className="fas fa-envelope mr-3" /> info@example.com</p>
              <p>
                <i className="fas fa-phone mr-3" /> + 01 234 567 88</p>
              <p>
                <i className="fas fa-print mr-3" /> + 01 234 567 89</p>
            </div>
            {/* Fourth column */}
          </div>
        </div>
        {/* Footer Links */}
        {/* Copyright */}
        <div className="footer-copyright py-3 text-center">
          <div className="container-fluid">
            © 2019 Copyright: <a href="https://mdbootstrap.com/education/bootstrap/" target="_blank"> MDBootstrap.com </a>
          </div>
        </div>
        {/* Copyright */}
      </footer>
      {/* Footer */}
      {/* Cart Modal */}
      <div className="modal fade cart-modal" id="cart-modal-ex" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div className="modal-dialog" role="document">
          {/* Content */}
          <div className="modal-content">
            {/* Header */}
            <div className="modal-header">
              <h4 className="modal-title font-weight-bold dark-grey-text" id="myModalLabel">Your cart</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/* Body */}
            <div className="modal-body">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product name</th>
                    <th>Price</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Product 1</td>
                    <td>100$</td>
                    <td>
                      <a>
                        <i className="fas fa-eraser" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Product 2</td>
                    <td>100$</td>
                    <td>
                      <a>
                        <i className="fas fa-eraser" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Product 3</td>
                    <td>100$</td>
                    <td>
                      <a>
                        <i className="fas fa-eraser" />
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">4</th>
                    <td>Product 4</td>
                    <td>100$</td>
                    <td>
                      <a>
                        <i className="fas fa-eraser" />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {/* Footer */}
            <div className="modal-footer">
              <button type="button" className="btn btn-outline-primary">Add to cart</button>
              <button type="button" className="btn btn-primary">Checkout</button>
            </div>
          </div>
          {/* Content */}
        </div>
      </div>
      {/* Cart Modal */}
    </div>
  );
};

export default CartPage;
