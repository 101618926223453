import { useState, useEffect } from 'react'
//import React, { useState } from 'react';
//import logo from './logo.svg';

import { useNavigate} from 'react-router-dom'
import Axios from 'axios'
import {
    MDBInput,
    MDBInputGroup,
    MDBBtn,
    MDBCheckbox,
    MDBValidation,
    MDBValidationItem, MDBCol, MDBRow, MDBRadio, MDBBtnGroup
  } from 'mdb-react-ui-kit';

function Register() {
  const server_app_api_php = window.SERVER_APP_API_PHP
  let navigate = useNavigate()

  const PHONE_REGEX =  /^\s*(?:\?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/ ;
  //const PHONE_REGEX =  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
  //const QUANTITY_REGEX = /^\d{1,9}$/
  const EMAIL_REGEX = /^[^@]+@[^@]+\.[^@]{2,4}$/


 let [handleSubmitBtn, setHandleSubmitBtn] = useState('')

   let [firstName, setFirstName] = useState('')
   let [lastName, setLastName] = useState('')
    let [phoneNumber, setPhoneNumber] = useState('')
    let [email, setEmail] = useState('')    
   let [sex, setsex] = useState('')
   let [relation, setRelation] = useState('')
   let [title, setTitle] = useState('')

    //let [verifyChoirLeaderPhone, setVerifyChoirLeaderPhone] = useState(false)
    const [validPhone, setValidPhone] = useState(false)
    const [phoneFocus, setPhoneFocus] = useState(false)
    const [validEmail, setValidEmail] = useState(false)
    const [emailFocus, setEmailFocus] = useState(false)
  

    useEffect(() => {
      const result = PHONE_REGEX.test(phoneNumber)
      setValidPhone(result)
      }, [phoneNumber])
  
     useEffect(() => {
      const result = EMAIL_REGEX.test(email)
      setValidEmail(result)
    }, [email])

  const handleSubmit = async e => {

   setHandleSubmitBtn('Loading')    
   
    var formData = new FormData(this)
    formData.append('apiType', 'registration')
    formData.append('firstName', firstName)
    formData.append('lastName', lastName)
    formData.append('phoneNumber', phoneNumber)
    formData.append('email', email)
    formData.append('sex', sex)
    formData.append('relation', relation)
    formData.append('title', title)
    Axios({
      method: 'post',
      url: `${server_app_api_php}/index.php`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(response => {
      setHandleSubmitBtn('')
      alert(response.data)
   setFirstName('')
    setLastName('')
    setPhoneNumber('')
    setEmail('')    
    setsex('')
    setRelation('')
    setTitle('')
    }).catch((err) => alert("Connection Error : Check Internet Connectivity"),
    setHandleSubmitBtn('')
    );    
};


  
  return (
    <>
   <div className="row">
  <div className="col-12">
    {/*
    <header>
        */}

<nav className="navbar navbar-expand-lg navbar-dark blue fixed-top scrolling-navbar col-12">
        <div className="container">
          <a className="navbar-brand" href="."><strong>Tabo's Kitchen Party</strong></a>
          <button className="navbar-toggler blue" type="button" data-toggle="collapse" data-target="#navbarSupportedContent-7"
            aria-controls="navbarSupportedContent-7" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent-7">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <a className="nav-link"  href="." >Home</a>
              </li>
              <li className="nav-item">
            <a className="nav-link "  href="RSVP" >RSVP</a>
          </li>
          <li className="nav-item">
            <a className="nav-link "  href="Download" >Download Your Wedding Card Here</a>
          </li>
            </ul>
          </div>
        </div>
      </nav>
    
    {/* Main Navigation */}


    <section className='mt-5'>
          <div class="row">
  <div class="col-12 col-md-12 col-lg-12 col-xl-12">
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 col-lg-6 col-md-10 col-sm-12 mx-auto mt-5'>
              <div className='card'>
                <div className='card-body'>
               {/* <Navbar /> */}
               
               
               
                  <div className='row mt-1'>
                    <h5 className='font-weight-500 my-1 text-left'>
                      Register Here
                    </h5>
                  </div>
                  <div class='text-center'>

    <hr class='mt-2' />   
                    <MDBRow className='row g-3' >

      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom01'
          onChange={e => setFirstName(e.target.value)}
        value={firstName}
          label='First name *' 
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setLastName(e.target.value)}
          value={lastName}
          label='Last name *'
        />
      </MDBCol>
      <MDBCol className='col-md-6 col-12' >
        <MDBInput
          id='validationCustom03'
          onChange={e => setEmail(e.target.value)}
          value={email}
          label='Email'
          onFocus={() => setEmailFocus(true)}
          onBlur={() => setEmailFocus(false)}
        />
        <p
      className='red-text'
          hidden={
              emailFocus === false || validEmail === true
          }
        >          
          4 to 24 characters. <br />
          Let It Be A Valid Email Address.
        </p>
      </MDBCol>
      <MDBCol className='col-md-6 col-12'>
        <MDBInput
          id='validationCustom02'
          onChange={e => setPhoneNumber(e.target.value)}
          value={phoneNumber}
          label='Phone Number'
          onFocus={() => setPhoneFocus(true)}
          onBlur={() => setPhoneFocus(false)}
        />
         <p
                  className='red-text'
                  hidden={
                      phoneFocus === false || validPhone === true
                  }
                >
                  There should be 10 numerical values.
                </p>
      </MDBCol>
   
      <MDBCol className='col-md-2 col-12 text-left'>
        <label>Title *</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setTitle(e.target.value)}
        value={title}
        >
            <option value=''>Choose Title</option>
            <option>Mr</option>
            <option>Mrs</option>
            <option>Miss</option>
            <option>Dr</option>
            <option>Sir</option>
            <option>Hon</option>
        </select>
      </MDBCol>

     <MDBCol className='col-md-4 col-12 text-left'>
        <label>Gender *</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setsex(e.target.value)}
        value={sex}
        >
            <option value=''>Choose Gender</option>
            <option>Male</option>
            <option>Female</option>
        </select>
      </MDBCol>

      
     <MDBCol className='col-md-6 col-12 text-left'>
        <label>Relation *</label>
        <select
        class='custom-select d-block w-100'
          id='validationCustom01'
          onChange={e => setRelation(e.target.value)}
        value={relation}
        >
            <option value=''>Choose Your Side</option>
            <option>Bride</option>
            <option>Groom</option>
        </select>
      </MDBCol>
     
           <div className='col-12'>
        <MDBBtn onClick={handleSubmit}
        disabled={firstName === '' || lastName === '' || sex === '' || title === '' || relation === '' ||
        handleSubmitBtn === 'Loading'}
             >
           <div class="spinner-border" role="status" hidden = {handleSubmitBtn === ''}>
</div>
          Submit</MDBBtn>
      </div>
    </MDBRow>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      </div>
      </div>
      </section>

     
  </div>

  
</div>

    </>
  );
}

export default Register;
