//import logo from './logo.svg'
import './App.css'
import Layout from './pages/Layout'
import Missing from './pages/Missing'
import Welcome from './pages/Welcome'
import RSVP from './pages/RSVP'
import Register from './pages/Register'
import Download from './pages/Download'
import CartPage from './pages/CartPage'
import Admin from './pages/Admin'

import { Routes, Route } from 'react-router-dom'

function App () {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public Routes*/}

        {/* We want To Protect These Routes*/}
        <Route path='/' element={<Welcome />} />        
        {/* catch all*/}
        <Route path='*' element={<Missing />} />
        <Route path='/RSVP' element={<RSVP />} />
        <Route path='/Register' element={<Register />} />
        <Route path='/Download' element={<Download />} />
        <Route path='/CartPage' element={<CartPage />} />
        <Route path='/Admin' element={<Admin />} />
      </Route>
    </Routes>
  )
}

export default App
